.radio-container {
    position: absolute;
    top: 7%;
    left: 52%;
    transform: translateX(-50%);
    color: white;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.60);
    padding: 10px;
    z-index: 9999;
    display: flex;
    align-items: center;
  }
  
  .radio-item {
    display: flex;
    align-items: center;
  }
  
  .radio-item input[type="radio"] {
    position: relative;
    top: 1px;
    margin-right: 3px;
  }
  
  .radio-item label {
    margin-left: 3px;
    color: white;
    cursor: pointer;
  }
  
  .separator {
    border-left: 1px solid white;
    height: 20px;
    margin: 0 10px;
  }
  